import { ChevronLeft, ChevronRight } from "lucide-react";
import { Carousel } from "react-bootstrap";

interface ImageGalleryProps {
  autoScroll: boolean;
  images: {
    key: string;
    url: string;
    size: {
      width: number;
      height: number;
    };
  }[];
}

export const ImageGallery: React.FC<ImageGalleryProps> = ({
  images,
  autoScroll,
}) => {
  if (!images.length) {
    return null;
  }

  return (
    <Carousel
      wrap
      controls
      interval={autoScroll ? 5000 : null}
      nextIcon={
        <div className="carousel-control-button">
          <ChevronRight size={40} strokeWidth={3} />
        </div>
      }
      prevIcon={
        <div className="carousel-control-button">
          <ChevronLeft size={40} strokeWidth={3} />
        </div>
      }
    >
      {images.map(({ key, url }) => (
        <Carousel.Item key={key}>
          <div
            className="w-full overflow-hidden bg-no-repeat bg-center bg-cover"
            style={{
              backgroundImage: `linear-gradient(
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0) 100%
              ), url("${url}")`,
            }}
          />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};
